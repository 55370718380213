/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Footer() {
  return (
    <MKBox component="footer" py={6}>
    <Container>
      <Grid container>
        <Grid
          item
          xs={12}
          lg={4}
          textAlign={{ xs: "center", lg: "left" }}
          mr="auto"
          mb={{ xs: 3, lg: 0 }}
        >
          <MKTypography variant="h6" textTransform="uppercase" mb={{ xs: 2, lg: 3 }}>
            Dementes Creativos
          </MKTypography>
          <Stack
            component="ul"
            direction="row"
            flexWrap="wrap"
            spacing={3}
            justifyContent={{ xs: "center", lg: "flex-start" }}
            pl={0}
            mb={3}
            sx={{ listStyle: "none" }}
          >
            <MKBox component="li">
              <MKTypography
                variant="button"
                fontWeight="regular"
                opacity={0.8}
                component={Link}
                href="/index"
              >
                Home
              </MKTypography>
            </MKBox>
            <MKBox component="li">
              <MKTypography
                variant="button"
                fontWeight="regular"
                opacity={0.8}
                component={Link}
                href="/pages/landing-pages/author"
              >
                Acerca de Nosotros
              </MKTypography>
            </MKBox>
            <MKBox component="li">
              <MKTypography
                variant="button"
                fontWeight="regular"
                opacity={0.8}
                component={Link}
                href="/pages/landing-pages/contact-us"
              >
                Contactenos
              </MKTypography>
            </MKBox>
          </Stack>
          <MKTypography variant="button" opacity={0.8}>
            Copyright © <script>document.write(new Date().getFullYear())</script>2023 DMC - Dementes Creativos
          </MKTypography>
        </Grid>
        <Grid item xs={12} lg={6} ml="auto" textAlign={{ xs: "center", lg: "right" }}>
          <MKTypography variant="body1" fontWeight="bold" mb={6} sx={{ fontSize: "1.125rem" }}>
          En el mundo de la tecnología, quien no avanza se queda atrás; innovar es la única forma de mantenerse relevante.
          </MKTypography>
          <MKTypography
            component={Link}
            href="https://x.com/DementesCreatCo"
            target="_blank"
            variant="body2"
            color="dark"
            opacity={0.5}
            mr={3}
          >
            <i className="fab fa-twitter" />
          </MKTypography>
          <MKTypography
            component={Link}
            href="https://www.instagram.com/dementescreativosco/"
            target="_blank"
            variant="body2"
            color="dark"
            opacity={0.5}
            mr={3}
          >
            <i className="fab fa-instagram" />
          </MKTypography>
        </Grid>
      </Grid>
    </Container>
  </MKBox>
  );
}

export default Footer;
