/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

// Images
import imageLaptop from "assets/images/laptop.jpg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Completamente Modular"
                    description="Realizado completamente por modulos que puedes agregar o eliminar segun la necesidad de tu negocio."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payment"
                    title="Pagos en Linea"
                    description="Contamos con integraciones a pasarelas de pago, implementa tu carrito de compras facilmente en un solo clic."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="computer"
                    title="Diferentes Tecnologias"
                    description="Tenemos tecnologías de vanguardia para ofrecer productos escalables, eficientes y adaptados a las necesidades."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="euro"
                    title="Sin Costos Innecesarios"
                    description="Solo pagas por los modulos que necesites, sin cobros adicionales y letra chica que altera tus presupuestos."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={imageLaptop}
              title="Conoce mas de nosotros"
              description="Si quieres saber un poco mas de nosotros, obtener mas información o contactarnos no dudes en hacerlo, estamos para escucharte."
              action={{
                type: "internal",
                route: "/pages/landing-pages/author",
                color: "info",
                label: "Mas Información",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
