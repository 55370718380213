// @mui icons
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "",
    //image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/dementescreativosco/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://x.com/DementesCreatCo",
    },
  ],
  menus: [
    {
      name: "Dementes Creativos",
      items: [
        { name: "Acerca de nosotros", href: "/pages/landing-pages/author" },
        //{ name: "Contactenos", href: "https://www.creative-tim.com/templates/free" },
      ],
    },
    {
      name: "Soporte",
      items: [
        { name: "Contactenos", href: "/pages/landing-pages/contact-us" },
        /*{ name: "knowledge center", href: "https://www.creative-tim.com/knowledge-center" },
        { name: "custom development", href: "https://services.creative-tim.com/" },
        { name: "sponsorships", href: "https://www.creative-tim.com/sponsorships" },*/
      ],
    },
    {
      name: "Terminos y Condiciones",
      items: [
        { name: "Terminos", href: "/pages/landing-pages/author" },
        /*{ name: "bits & snippets", href: "https://www.creative-tim.com/bits" },
        { name: "affiliate program", href: "https://www.creative-tim.com/affiliates/new" },*/
      ],
    },
    {
      name: "Legal",
      items: [
        { name: "Politica de privacidad", href: "/pages/landing-pages/contact-us" },
        //{ name: "Politica de Privacidad", href: "https://www.creative-tim.com/privacy" },
        //{ name: "licenses (EULA)", href: "https://www.creative-tim.com/license" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Todos los derechos reservados. Copyright &copy; {date} DMC by{" "}
      {/*<MKTypography
        component="a"
        href="https://www.creative-tim.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Dementes Creativos
      </MKTypography>*/}
      Dementes Creativos.
    </MKTypography>
  ),
};
