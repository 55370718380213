/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/felipe.jpg";
import team2 from "assets/images/fernando.jpg";
import team3 from "assets/images/felipeM.jpg";
import team4 from "assets/images/carolina.jpg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Nuestro Equipo
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Nuestro está formado por expertos apasionados en tecnología y desarrollo de software, 
              con una visión compartida: transformar ideas en soluciones digitales de impacto. Compuesto por desarrolladores, 
              diseñadores, ingenieros de software y un gran equipo comercial, nuestro equipo combina experiencia técnica y creatividad 
              para abordar desafíos complejos y crear productos únicos y funcionales para cada tipo de negocio.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Felipe Rodriguez"
                position={{ color: "info", label: "CEO" }}
                description="Ingeniero de Sistemas - UDEC. Programador Senior en PHP / Java / React / Bases de Datos / AWS."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Fernando Marquez"
                position={{ color: "info", label: "CTO" }}
                description="Ingeniero de Sistemas - UDEC. Programador Senior en .NET / Python / Angular / Bases de Datos / Azure."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Felipe Maldonado"
                position={{ color: "info", label: "CAO" }}
                description="Ingeniero de Sistemas - UDEC. Business Analytics / Business Intelligence / Power Bi / Tableau / SAP BI / Bases de Datos"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Carolina Quintero"
                position={{ color: "info", label: "CSO" }}
                description="Profesional en Relaciones Economicas Internacionales - U Autonoma Colombia / Especialista Gerencia Servicio / Especialista Gerencia Comercial - U Sabana."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
