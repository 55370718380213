/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import profilePicture from "assets/images/dementes-creativos-new.jpeg";

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Burce Mars" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h3">Dementes Creativos</MKTypography>
                <MKButton variant="outlined" color="info" size="small">
                  Seguir
                </MKButton>
              </MKBox>
              <Grid container spacing={3} mb={3}>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    1&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Posts
                  </MKTypography>
                </Grid>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    7&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Followers
                  </MKTypography>
                </Grid>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    14&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Following
                  </MKTypography>
                </Grid>
              </Grid>
              <MKTypography variant="body1" fontWeight="light" color="text" textAlign="justify" >
                Dementes Creativos nacio en el 2014, idea de Felipe Rodriguez con su amigo, socio y cofundador, Jeiver Ardila, en Fusagasuga
                despues de graduarse como Ingenieros de Sistemas de la Universidad de Cundinamarca, la idea era realizar una empresa
                que pudiera cubrir las falencias tecnologicas de cualquier persona que tuviera un negocio propio o una idea del mismo, 
                y poder llegar a empresas a nivel local, departamental y en otros paises. <br />
                Es asi como en esos años logramos realizar mas de 15 proyectos entre los cuales se encontraban tiendas virtuales,
                paginas personales, empresas de transporte, aseguradoras de riesgos agricolas, seguimiento de rutas escolares, trazo de mapas 
                con GPS y resultados en linea, historias medicas, historias veterinarias, y una gran cantidad de software a la medida que necesitaban nuestros clientes como inventarios,
                facturacion, carrito de compras, clientes, usuarios, nominas entre otros.<br /><br />
                Por cosas de la vida y del destino, la empresa en el año 2019 decidio separarse, y cada quien hacer su camino
                para intentar nuevos rumbos, pero nuestros clientes nos pedian que volvieramos con mas tecnologia, software y mas
                capacidades para poder soportar cualquier necesidad de los mismos a nivel tecnologico.<br /><br />
                Desde el año 2023 hemos iniciado nuevamente a proporcionar soluciones tecnologicas a nuestros clientes, 
                una labor que cada vez es mas fuerte, ya que hemos diseñado un software tecnologico que tiene todas las necesidades del cliente,
                funcional a traves de modulos, que dependiendo lo que el cliente necesite, un pago mensualmente o anualmente de lo que necesite, a su medida, siempre
                con ventajas como grandes servidores en la nube, seguridad en la programacion, bases de datos robustas, personal idoneo para
                poder soportar cualquier inquietudad o mejora que nuestos clientes soliciten, desarrollando en lenguajes
                fuertes como Java, .Net y PHP, con frameworks que estan a la vanguardia, tambien con diseños bastante sociables,
                nada complicado, que le permita trabajar mas rapida y facilmente, realizados en tecnologias como React y Angular, 
                tambien bases de datos como MySQL, SQL Server y Oracle. <br /><br />
                En este 2024 contamos con 2 personas de la Universidad de Cundinamarca que son especialistas en todo el tema de desarrollo,
                tanto para aplicaciones de escritorio, como para web, y con una persona experta en todo el tema de manejo de datos,
                bases de datos, integridad, informacion y reportes en linea y con una facilidad para nuestros clientes, tambien con nosotros,
                una mujer que su inteligencia sobre sale ante cualquier evento, comercial con un caracter fuerte pero que da una confianza y
                seguridad para cualquier necesidad de nuestros clientes, ademas de programadores Jr que ayudan en todo el tema de soporte
                24/7 y que reciben cualquier solicitud de soporte, de mejora, de calidad, de innovacion que nuestros clientes necesiten.<br /><br />
                Asi pues esperamos que cualquier necesidad tecnologica, podamos resolverla y darle un producto de calidad y a su medida, 
                sin letras pequeñas y cobros innecesarios para Ud y su negocio.

                <MKTypography
                  component="a"
                  href="#"
                  variant="body1"
                  fontWeight="light"
                  color="info"
                  mt={3}
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      transform: `translateX(3px)`,
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translateX(6px)`,
                    },
                  }}
                >
                </MKTypography>
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
