/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
import post1 from "assets/images/examples/php.jpeg";
import post2 from "assets/images/examples/java.jpeg";
import post3 from "assets/images/examples/net.jpg";
import post4 from "assets/images/examples/react.jpg";
import post5 from "assets/images/examples/angular.png";
import post6 from "assets/images/examples/aws.jpg";
import post7 from "assets/images/examples/mysql.jpeg";
import post8 from "assets/images/examples/jenkins.png";
import post9 from "assets/images/examples/git.png";
import post10 from "assets/images/examples/ddev.png";
import post11 from "assets/images/examples/docker.png";
import post12 from "assets/images/examples/sqlserver.png";

function Places() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            Nuestras Tecnologias
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post1}
              title="PHP"
              description="PHP es un lenguaje de programación de código abierto, ampliamente utilizado para el desarrollo web, diseñado para crear aplicaciones de servidor."
              action={{
                type: "external",
                route: "php.net",
                color: "info",
                label: "Leer Mas",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post2}
              title="Java"
              description="Java es un lenguaje de programación robusto, orientado a objetos y ampliamente utilizado para el desarrollo de aplicaciones de escritorio, móviles y web."
              action={{
                type: "external",
                route: "oracle.com/java",
                color: "info",
                label: "Leer Mas",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post3}
              title=".Net"
              description="Es una plataforma de desarrollo de software de código abierto creada por Microsoft, que permite construir y ejecutar aplicaciones en una variedad de entornos."
              action={{
                type: "external",
                route: "dotnet.microsoft.com",
                color: "info",
                label: "Leer Mas",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post4}
              title="React"
              description="Biblioteca de JavaScript de código abierto, desarrollada por Meta (Facebook), utilizada para crear interfaces de usuario interactivas y eficientes."
              action={{
                type: "external",
                route: "react.dev",
                color: "info",
                label: "Leer Mas",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post5}
              title="Angular"
              description="Framework de desarrollo de aplicaciones web de código abierto, creado y mantenido por Google. Utiliza TypeScript y permite construir aplicaciones web."
              action={{
                type: "external",
                route: "angular.io",
                color: "info",
                label: "Leer Mas",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post6}
              title="AWS"
              description="Plataforma de servicios en la nube de Amazon, que ofrece una amplia gama de herramientas y soluciones de infraestructura."
              action={{
                type: "external",
                route: "aws.amazon.com",
                color: "info",
                label: "Leer Mas",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post7}
              title="MySQL"
              description="Bases de datos relacional de código abierto, ampliamente utilizado para almacenar, organizar y acceder a grandes volúmenes de datos."
              action={{
                type: "external",
                route: "mysql.com",
                color: "info",
                label: "Leer Mas",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post12}
              title="SQL Server"
              description="Sistema de gestión de bases de datos relacional desarrollado por Microsoft, diseñado para almacenar, administrar y recuperar datos de aplicaciones empresariales."
              action={{
                type: "external",
                route: "microsoft.com/sql-server",
                color: "info",
                label: "Leer Mas",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post8}
              title="Jenkins"
              description="Herramienta de automatización de código abierto para integración continua y entrega continua (CI/CD). Ayuda a los desarrolladores a automatizar."
              action={{
                type: "external",
                route: "jenkins.io",
                color: "info",
                label: "Leer Mas",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post9}
              title="Git"
              description="Sistema de control de versiones distribuido que permite a los desarrolladores gestionar y rastrear cambios en el código fuente de manera colaborativa y eficiente."
              action={{
                type: "external",
                route: "git-scm.com",
                color: "info",
                label: "Leer Mas",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post10}
              title="DDEV"
              description="Herramienta de desarrollo local de código abierto para la creación de entornos para proyectos en contenedores, útil para proyectos web de PHP."
              action={{
                type: "external",
                route: "ddev.com",
                color: "info",
                label: "Leer Mas",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post11}
              title="Docker"
              description="Permite a los desarrolladores crear, desplegar y ejecutar aplicaciones en contenedores, proporcionando entornos aislados y replicables."
              action={{
                type: "external",
                route: "docker.com",
                color: "info",
                label: "Leer Mas",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
